<template>
  <div
    class="charter px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <div class="flex flex-wrap">
      <div class="w-full sm:w-1/6"></div>
      <div class="w-full md:w-2/3">
        <img class="m-auto mb-8" src="@/assets/balls-min.png" />
        <p style="text-align: center; font-size: 20px; color: #7e8890;">
          The Explosives Testing Users Group™ exists to facilitate improving and
          standardizing in-process energetic material response characterization
          methods. We meet annually to accomplish the objectives of the Charter.
        </p>
        <TitleComponent class="py-8" title="CHARTER" size="large" />
        <p style="text-align: center; font-size: 20px; color: #7e8890;">
          The Explosives Testing Users Group will collaborate to systematically
          minimize the variability associated with energetic materials testing
          to enable consistent/repeatable test data and interpretation of test
          results. This will be accomplished by:
        </p>
        <ul class="checklist pt-6" style="font-size:20px;line-height:34px;">
          <li class="fusion-li-item">
            <span class="icon-wrapper circle-no"
              ><i class="fusion-li-icon fa-check fas" style="color:#f09a3e;"></i
            ></span>
            <div
              class="fusion-li-item-content"
              style="margin-left:48px;color:#7f8991;"
            >
              Developing procedures and methods
            </div>
          </li>
          <li class="fusion-li-item">
            <span class="icon-wrapper circle-no"
              ><i class="fusion-li-icon fa-check fas" style="color:#f09a3e;"></i
            ></span>
            <div
              class="fusion-li-item-content"
              style="margin-left:48px;color:#7f8991;"
            >
              Applying technologies
            </div>
          </li>
          <li class="fusion-li-item">
            <span class="icon-wrapper circle-no"
              ><i class="fusion-li-icon fa-check fas" style="color:#f09a3e;"></i
            ></span>
            <div
              class="fusion-li-item-content"
              style="margin-left:48px;color:#7f8991;"
            >
              Reaching consensus
            </div>
          </li>
          <li class="fusion-li-item">
            <span class="icon-wrapper circle-no"
              ><i class="fusion-li-icon fa-check fas" style="color:#f09a3e;"></i
            ></span>
            <div
              class="fusion-li-item-content"
              style="margin-left:48px;color:#7f8991;"
            >
              Performing periodic “Round Robin” test series on standard
              materials
            </div>
          </li>
        </ul>
      </div>
      <div class="w-full md:w-1/6"></div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";

export default {
  components: {
    TitleComponent
  }
};
</script>

<style lang="scss">
.charter {
  ul {
    list-style: none;
    li {
      position: relative;
    }
  }

  ul li:before {
    top: 5px;
    position: absolute;
    content: url("../assets/check-solid.svg");
    filter: invert(70%) sepia(90%) saturate(601%) hue-rotate(326deg)
      brightness(94%) contrast(100%);
  }
}
</style>
